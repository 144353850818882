import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - Instaloop"
  const activePageText = "Case Study Instaloop"

  // Main Content Area
  const type =
    "1 Customer Facing Website + 1 Chrome Extension";

  const title = "Turns any instagram hashtag into a live presentation."
  const body1 = "The Challenge - Instaloop could not use Instgram's direct API due to extremely limits imposed by instagram themselves."
  const body2 = "The Solution - We decided to build a chrome extension that modifieds an instragram webpage directly! This way, no need to use any form of API."
  const body3 = "After 3 weeks of development, we were able to build, test and launch this product with a freemium model"
  const results = "The Result - A very cool, fun and exciting live hashtag presentation for events."


  // Side bar content area
  const clientName = "HD Group"
  const clientLink = "https://hdgroup.ca"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "North York, ON, Canada"
  const technologies = "Front-end: HTML, CSS, JS, Manifest V3.  Back-end: Firebase"
  const completionDate = "2023 April 30"

  const products = [{ hrefName: "Instaloop - Extension", href: "https://chrome.google.com/webstore/detail/instaloop/pfdkhajgmepgjmnfeghbgoeadmandhaf" },
  { hrefName: "Instaloop - Web", href: "https://instaloop.ca" },
  ]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
